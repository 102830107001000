
import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import GetStarted from "../../components/info-site/GetStarted"
import CaseStudyNinjaKitchen from "../../components/info-site/CaseStudyNinjaKitchen";

const SecretSales = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="ninjaKitchenCaseTitle" descriptionId="ninjaKitchenCaseSubTitle" />
            <CaseStudyNinjaKitchen
              title="ninjaKitchenCaseTitle"
              subTitle="ninjaKitchenCaseSimpleSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default SecretSales;